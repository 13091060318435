<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Mes projets
            </h1>
        </div>

        <div class="k-page-body text-left">
            <TableUserProjects
                :user-id="user.id"
                clickTarget="Project"
                perPage="12"
            />
        </div>
    </div>
</template>

<script>
import TableUserProjects from "@/components/model/user/TableUserProjects";
export default {
    components: {
        TableUserProjects,
    },

    data: function() {
        return {
            user: this.$store.state.user.user,
        };
    },
};
</script>
